import React, { useState, useRef } from "react";
import Modal from "react-modal";

import { ConfirmInformation, AgendamentoSelect } from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    margin: "0 auto",
    padding: "25px",
    overflow: "hidden",
    zIndex: 3,
  },
  overlay: {
    zIndex: 3,
    backgroundColor: "#44444480",
  },
};

interface Props {
  time: {
    time: Date;
    parsedTime: string;
  };
  sector: number;
  isOpen: boolean;
  toggleModalVisible: () => void;
  handleAddAppointment(
    sector: number,
    time: Date,
    subject: string
  ): Promise<void>;
}

Modal.setAppElement("#root");

const ConfirmModal: React.FC<Props> = ({
  time,
  sector,
  isOpen,
  toggleModalVisible,
  handleAddAppointment,
}: Props) => {
  const [selectedAgendamento, setSelectedAgendamento] = useState("");
  const subjectRef = useRef<HTMLInputElement>(null);

  function handleSelectAgendamento(
    e: React.ChangeEvent<HTMLSelectElement>
  ): void {
    setSelectedAgendamento(String(e.target.value));
  }

  switch (sector) {
    case 1:
      return (
        <div>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalVisible}
            style={customStyles}
          >
            <ConfirmInformation>
              <strong>CONFIRMAR AGENDAMENTO</strong>

              <p>
                Confirmar atendimento para
                <strong> {time.parsedTime}</strong>?
              </p>

              <AgendamentoSelect
                name="AgendamentoSelect"
                onChange={handleSelectAgendamento}
                required
              >
                <option selected hidden disabled value="">
                  SELECIONE UMA OPÇÃO
                </option>
                <option value="Entrega de documentos">
                Entrega de documentos
                </option>
                <option value="Inscrição Provisória">
                Inscrição Provisória
                </option>
                <option value="Inscrição Principal">
                Inscrição Principal
                </option>
                <option value="Transferência de Inscrição">
                Transferência de Inscrição
                </option>
                <option value="Inscrição Especialista">
                Inscrição Especialista
                </option>
                <option value="Inscrição EPAO">
                Inscrição EPAO
                </option>
                <option value="Apostilamento">
                Apostilamento
                </option>
                <option value="Solicitação de 2ª Via de Carteira">
                Solicitação de 2ª Via de Carteira                </option>
                <option value="Suspensão de Inscrição">
                Suspensão de Inscrição
                </option>
                <option value="Cancelamento de Inscrição">
                Cancelamento de Inscrição
                </option>
                <option value="Registro de Curso de Especialidade">
                Registro de Curso de Especialidade
                </option>
                <option value="Exclusão de Responsável Técnico">
                Exclusão de Responsável Técnico
                </option>
                <option value="Inclusão de Responsável Técnico">
                Inclusão de Responsável Técnico
                </option>
                <option value="Registro - Apostilamento (alteração de nome/sobrenome)">
                Autorização de 90 dias
                </option>

                <option value="Negociação de Débito">
                Negociação de Débito
                </option>
                <option value="Parcelamento">
                Parcelamento
                </option>
                <option value="Prescrição de Débito">Prescrição de Débito</option>
                
                
              </AgendamentoSelect>

              <input
                ref={subjectRef}
                type="hidden"
                value={selectedAgendamento}
                placeholder="Assunto"
              />

              <button
                type="button"
                onClick={
                  () =>
                    handleAddAppointment(
                      sector,
                      time.time,
                      String(selectedAgendamento)
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                Confirmar
              </button>
            </ConfirmInformation>
          </Modal>
        </div>
      );
    case 2:
      return (
        <div>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalVisible}
            style={customStyles}
          >
            <ConfirmInformation>
              <strong>CONFIRMAR AGENDAMENTO</strong>

              <p>
                Confirmar atendimento para
                <strong> {time.parsedTime}</strong>?
              </p>

              <AgendamentoSelect
                name="AgendamentoSelect"
                onChange={handleSelectAgendamento}
                required
              >
                <option selected hidden disabled value="">
                  SELECIONE UMA OPÇÃO
                </option>
                <option value="Entrega de documentos">
                Entrega de documentos
                </option>
                <option value="Inscrição Provisória">
                Inscrição Provisória
                </option>
                <option value="Inscrição Principal">
                Inscrição Principal
                </option>
                <option value="Transferência de Inscrição">
                Transferência de Inscrição
                </option>
                <option value="Inscrição Especialista">
                Inscrição Especialista
                </option>
                <option value="Inscrição EPAO">
                Inscrição EPAO
                </option>
                <option value="Apostilamento">
                Apostilamento
                </option>
                <option value="Solicitação de 2ª Via de Carteira">
                Solicitação de 2ª Via de Carteira                </option>
                <option value="Suspensão de Inscrição">
                Suspensão de Inscrição
                </option>
                <option value="Cancelamento de Inscrição">
                Cancelamento de Inscrição
                </option>
                <option value="Registro de Curso de Especialidade">
                Registro de Curso de Especialidade
                </option>
                <option value="Exclusão de Responsável Técnico">
                Exclusão de Responsável Técnico
                </option>
                <option value="Inclusão de Responsável Técnico">
                Inclusão de Responsável Técnico
                </option>
                <option value="Registro - Apostilamento (alteração de nome/sobrenome)">
                Autorização de 90 dias
                </option>

                <option value="Negociação de Débito">
                Negociação de Débito
                </option>
                <option value="Parcelamento">
                Parcelamento
                </option>
                <option value="Prescrição de Débito">Prescrição de Débito</option>
              </AgendamentoSelect>

              <input
                ref={subjectRef}
                type="hidden"
                value={selectedAgendamento}
                placeholder="Assunto"
              />

              <button
                type="button"
                onClick={
                  () =>
                    handleAddAppointment(
                      sector,
                      time.time,
                      String(selectedAgendamento)
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                Confirmar
              </button>
            </ConfirmInformation>
          </Modal>
        </div>
      );
    case 5:
      return (
        <div>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalVisible}
            style={customStyles}
          >
            <ConfirmInformation>
              <strong>CONFIRMAR AGENDAMENTO</strong>

              <p>
                Confirmar atendimento para
                <strong> {time.parsedTime}</strong>?
              </p>

              <input
                ref={subjectRef}
                type="text"
                placeholder="Assunto"
                maxLength={30}
              />

              <button
                type="button"
                onClick={
                  () =>
                    handleAddAppointment(
                      sector,
                      time.time,
                      String(subjectRef.current?.value)
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                Confirmar
              </button>
            </ConfirmInformation>
          </Modal>
        </div>
      );
    default:
      return <p />;
  }
};

export default ConfirmModal;
