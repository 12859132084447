export const holidays = [
  new Date(2022, 11, 31),
  new Date(2022, 11, 25),
  new Date(2023, 0, 1),
  new Date(2023, 1, 16),
  new Date(2023, 3, 2),
  new Date(2023, 3, 21),
  new Date(2023, 4, 1),
  new Date(2023, 5, 3),
  new Date(2023, 8, 7),
  new Date(2023, 9, 12),
  new Date(2023, 10, 2),
  new Date(2023, 10, 15),
  new Date(2023, 11, 25),
  new Date(2023, 11, 31),
  new Date(2024, 0, 1),
];

export const libraryVacation = [
  new Date(2020, 11, 15),
  new Date(2020, 11, 16),
  new Date(2020, 11, 17),
  new Date(2020, 11, 18),
  new Date(2020, 11, 19),
  new Date(2020, 11, 20),
  new Date(2020, 11, 21),
  new Date(2020, 11, 22),
  new Date(2020, 11, 23),
  new Date(2020, 11, 24),
  new Date(2020, 11, 25),
  new Date(2020, 11, 26),
  new Date(2020, 11, 27),
  new Date(2020, 11, 28),
  new Date(2020, 11, 29),
  new Date(2020, 11, 30),
  new Date(2020, 11, 31),
  new Date(2021, 0, 1),
  new Date(2021, 0, 2),
  new Date(2021, 0, 3),
  new Date(2021, 0, 4),
  new Date(2021, 0, 5),
  new Date(2021, 0, 6),
  new Date(2021, 0, 7),
  new Date(2021, 0, 8),
  new Date(2021, 0, 9),
  new Date(2021, 0, 10),
  new Date(2021, 0, 11),
  new Date(2021, 0, 12),
  new Date(2021, 0, 13),
];

export const mumpsMigration = [
  new Date(2021, 3, 1),
  new Date(2021, 3, 2),
  new Date(2021, 3, 3),
  new Date(2021, 3, 4),
  new Date(2021, 3, 5),
  new Date(2021, 3, 6),
  new Date(2021, 3, 7),
  new Date(2021, 3, 8),
  new Date(2021, 3, 9),
  new Date(2021, 3, 10),
  new Date(2021, 3, 11),
];
