import React from "react";

interface MessageData {
  sector: number;
}

const Message: React.FC<MessageData> = ({ sector }) => {
  return (


    
    <p>
Conselho Regional de Odontologia CRO-AL <br />Agendamento para Atendimento Presencial
      <br />
      Horário de Atendimento 08:00hàs 14:00h<br />
      Em caso de dúvidas, entre em contato :{" "}
      <strong>(82) 3223-5125  /  3221-1726  /  (82) 99142-8344</strong>.
      
    </p>
  );
};

export default Message;
