import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { darken, lighten } from 'polished';

import BackgroundImage from '../../../assets/background.png';

interface AvailableProps {
  enabled: boolean;
}

interface TimesProps {
  columns?: number;
}

export const Container = styled.div`
  height: 100%;

  @media (min-width: 650px) {
    overflow: hidden;
  }
`;

export const Content = styled.div`
  height: 100%;
  background: url(${BackgroundImage}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    height: auto;
  }
`;

export const MobileNavigation = styled.div`
  @media (max-width: 650px) {
    margin-bottom: 40px;
    margin-top: 40px;

    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;

      a {
        text-decoration: none;
        color: #990000;
        transition: color 0.2s;

        &:hover {
          color: ${lighten(0.1, '#990000')};
        }
      }
    }
  }

  @media (min-width: 651px) {
    display: none;
  }
`;

export const AvailableTimesContainer = styled.div`
  background-color: rgba(255, 255, 255);
  border-radius: 10px;

  padding: 40px;
  margin: 0 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 60%;

  h3 {
    margin-top: 20px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    width: 90%;

    margin-bottom: 40px;

    h3 {
      font-size: 14px;
    }
  }
`;
export const BoxH2 = styled.h2`
  background-color: #D3D3D3;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  color: #000000;

  font-size: 25px;
  font-weight: 0;
`;

export const BoxContainer = styled.div`
  background: #F8F8FF;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  padding: 15px 10px 10px 15px;
  margin-top: 20px;

  justify-content: center; /* Centralizar horizontalmente */
`;

export const PickerContainer = styled.div`
  display: flex;
  justify-content: center; /* Centralizar horizontalmente */
  width: 100%;
`;

export const SectorSelect = styled.select`
  font-size: 20px;
  width: 100%;
  border-radius: 4px;
  background: #f0f0f0;
  margin-bottom: 10px;
  border: none;
  padding: 5px 30px;
  align-self: center;
  text-align:center;

  cursor: pointer;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
`;

export const Picker = styled(DatePicker)`
  border: none;
  font-size: 20px;
  width: 100%;
  color: #000;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  padding: 5px 0;
  margin: 0 auto;


  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  &:hover {
    cursor: pointer;
  }
`;

export const AvailableTimes = styled.div`
  background: #000;
  border-radius: 10px;
  text-align: center;
  padding: 10px 40px;
  border: 2px solid #990000;
  color: #fff;
  background-color: #990000;
  transition: opacity 0.3s;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.enabled ? 1 : 0.5)};
  ${(props) => !props.enabled && 'pointer-events: none;'}


  opacity: ${(props: AvailableProps) => (props.enabled ? 1 : 0.5)};

  ${(props: AvailableProps) => !props.enabled && 'pointer-events: none;'}

  &:hover {
    cursor: ${(props: AvailableProps) =>
      props.enabled ? 'pointer' : 'not-allowed'};
    opacity: ${(props: AvailableProps) => props.enabled && 0.8};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const CountBadge = styled.span`
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 22px;
`;


export const Times = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: ${(props: TimesProps) =>
    props.columns === 2 && 'repeat(2, 1fr)'};
  row-gap: 20px;
  column-gap: 20px;

  margin-top: 40px;

  .selected {
    border: 2px solid #fff;
    background-color: ${darken(0.2, '#990000')};
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Subtitle = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 40px;
font-size: 17px;

> div {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 650px) {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  > div {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #990000;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > div.first-square {
    background-color: #990000;
  }

  > div.second-square {
    background-color: #990000;
    opacity: 0.5;
  }

  > div.third-circle {
    background-color: #fff;
    border: 2px solid #990000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 650px) {
    font-size: 15px;
  }
}

@media (max-width: 650px) {
  flex-direction: column;
  align-items: flex-start;
}
`;
export const ConfirmButton = styled.button`
  border: 0;
  background: none;
  padding: 10px 40px;
  background-color: #990000;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 40px;
  transition: opacity 0.3s;

  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
